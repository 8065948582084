import React, { FC } from 'react'
import Image from 'next/image'
import { LinkPlain } from '../link'

const Wavefarm = () => (
  <div className='w-20 h-20 sm:w-24 sm:h-24'>
    <LinkPlain
      external
      href="https://wavefarm.org"
    >
      <Image
        className="w-full relative dark:invert"
        draggable={false}
        src="img/wavefarm-logo.png"
        loader={
          m => m.width < 500
            ? `https://assets.august.black/${m.src.split('.').slice(0, -1)}-${m.width}.webp`
            : `https://assets.august.black/${m.src}`
        }
        width={500}
        height={500}
        blurDataURL="data:image/webp;base64,UklGRpwAAABXRUJQVlA4WAoAAAAQAAAACQAACQAAQUxQSFwAAAABcCTbtunq2j9my2Nw+rbds+3k/EREQEWSKsBqF/H7I9sllZ3h8TrdlkPFDAAW8Mm8PSa99aBX/X2zm+kwNtuu//IDZGa5V2Vv8fgc6da1xGob8fuihwVQkaQyAFZQOCAaAAAAMAEAnQEqCgAKAAFAJiWkAANwAP79AZvOBAA="
        alt='wave farm logo'
        placeholder="blur"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      />
    </LinkPlain>

  </div>
)
const Atlas = () => (
  <div className='flex-none w-20 h-20 sm:w-24 sm:h-24 flex place-items-center'><LinkPlain external href="https://www.colorado.edu/atlas/">
    <Image
      className="w-full relative dark:invert"
      draggable={false}
      loader={
        m => m.width < 172
          ? `https://assets.august.black/${m.src.split('.').slice(0, -1)}-${m.width}.webp`
          : `https://assets.august.black/${m.src}`
      }
      alt='atlas logo'
      placeholder="blur"
      src="img/atlas-logo.svg"
      width={172}
      height={37}
      style={{ width: "172px" }} // needed for Firefox
      blurDataURL="data:image/webp;base64,UklGRlQAAABXRUJQVlA4WAoAAAAQAAAACQAAAQAAQUxQSBUAAAAADqNetIoAZVa5UG6maHKoVXuRk3wAVlA4IBgAAAAwAQCdASoKAAIAAUAmJaQAA3AA/v02aAA="
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    />
  </LinkPlain></div>
)

const DCMP = () => (
  <div className="w-36 h-20 sm:h-24 sm:w-56 flex-none flex place-items-center">
    <LinkPlain external href="https://www.colorado.edu/cmci/dcmp">
      <Image
        className="w-full relative dark:invert"
        draggable={false}
        loader={m => m.width < 1036
          ? `https://assets.august.black/${m.src.split('.').slice(0, -1)}-${m.width}.webp`
          : `https://assets.august.black/${m.src}`
        }
        alt='DCMP logo'
        src="img/dcmp-logo.png"
        width={1036}
        height={199}
        blurDataURL="data:image/webp;base64,UklGRlYAAABXRUJQVlA4WAoAAAAQAAAACQAAAQAAQUxQSBUAAAAAJiolKigoHiEmJRMXERAUFRwoJAQAVlA4IBoAAAAwAQCdASoKAAIAAUAmJaQAA3AA/vrvxwrAAA=="
        placeholder="blur"
        sizes="(max-width: 768px) 100vw,(max-width: 1200px) 50vw, 33vw"
      />
    </LinkPlain>

  </div>
)

type LogoNames = 'atlas' | 'dcmp' | 'wavefarm'

export const Logos: FC<{ logos: Array<LogoNames> }> = ({
  logos
}) => (
  <>
    <div className="font-bold">Supported by:</div>
    <div className="flex flex-wrap gap-2 sm:gap-4 md:gap-6 justify-start">
      {
        logos.map(l => l === 'atlas'
          ? <Atlas key='atlas' />
          : l === 'dcmp'
            ? <DCMP key='dcmp' />
            : l === 'wavefarm'
              ? <Wavefarm key='wavefarm' />
              : null
        )
      }
    </div>
  </>
)
