import React, { FC, ReactNode } from 'react'
import { Swiper } from './swiper'
import Image from 'next/image'
import { adjustVolume, mainAudioId } from './utils'

type SubProps = { sub?: string; subLabel?: string }
type SubsProps = { subs?: Array<SubProps>; src: string }
type CaptionProps = { caption?: React.ReactNode }

type VideoProps = {
  kind: "video"
  bg?: string
  imgClass?: string
  src: string
  poster?: string
  caption?: ReactNode
  subs?: Array<SubProps>
}

export type GenericProps = {
  kind: 'generic'
  src: string
  children: ReactNode
  caption?: ReactNode
}
export type ImageProps = {
  kind: "image",
  src: string // StaticImageData
  width: number
  height: number
  blurDataURL: string
  alt: string
  bg?: string
  imgClass?: string
  position?: string
  caption?: ReactNode
}

export type MediaProps = VideoProps | GenericProps | ImageProps

type MediaGroupProps = {
  media: MediaProps[]
}

type MediaGridProps = {
  media: MediaProps[]
  aspect?: number
}

const Caption: FC<CaptionProps> = ({ caption }) =>
  caption ? (
    <div className="absolute bottom-0 text-xs lg:text-sm xl:text-sm p-2 bg-slate-900 text-slate-100 w-full opacity-75">
      {caption}
    </div>
  ) : null

const SubTrack: FC<SubProps> = ({ sub, subLabel }) =>
  sub && sub.trim() !== '' ? (
    <track label={subLabel} kind="subtitles" srcLang="en" src={sub} default />
  ) : null

const SubTracks: FC<SubsProps> = ({ src, subs }) =>
  subs && subs.length ? (
    <React.Fragment>
      {subs.map((s) => (
        <SubTrack {...s} key={src + s.subLabel} />
      ))}
    </React.Fragment>
  ) : null

const stopEvent = (e: React.KeyboardEvent | React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

const pausePlayingAudio = (audioId: string) => {
  const audioEls = document.getElementsByTagName('audio')
  for (let i = 0; i < audioEls.length; i++) {
    const el = audioEls.item(i)
    if (el.id !== audioId && el.id !== mainAudioId) {
      adjustVolume(el, 0.0)
        .then(() => el.pause())
        .then(() => adjustVolume(el, 1.0))
        .catch(console.error)
    }
  }
}

const pausePlayingVideo = (videoId: string) => {
  const videoEls = document.getElementsByTagName('video')
  for (let i = 0; i < videoEls.length; i++) {
    const el = videoEls.item(i)
    if (el.id !== videoId && el.id !== mainAudioId) {
      adjustVolume(el, 0.0)
        .then(() => el.pause())
        .then(() => adjustVolume(el, 1.0))
        .catch(console.error)
    }
  }
}

const Video: FC<VideoProps> = ({ src, subs, caption, poster, bg, imgClass }) => {
  const handlePlay = () => {
    pausePlayingAudio(src)
    pausePlayingVideo(src)
  }
  return (
    <div className={"w-full h-full relative flex items-center " + (bg || '')} >
      <video
        className={"w-full relative " + (imgClass || '')}
        onPlay={handlePlay}
        poster={poster}
        controls
        id={src}
        onKeyUp={stopEvent}
        onKeyDown={stopEvent}
        onClick={stopEvent}
        playsInline
      >
        <source src={src} />
        <SubTracks subs={subs} src={src} />
      </video>
      <Caption caption={caption} />
    </div>
  )
}

const ImageLocal: FC<ImageProps> = ({
  blurDataURL,
  alt,
  src,
  width,
  bg,
  imgClass,
  caption,
  position
}) => (
  <div className={"w-full h-full relative " + (bg || '')} >
    <Image
      draggable={false}
      loader={m => m.width < width
        ? `https://assets.august.black/${m.src.split('.').slice(0, -1)}-${m.width}.webp`
        : `https://assets.august.black/${m.src}`
      }
      alt={alt}
      src={src}
      className={imgClass || "object-cover"}
      fill={true}
      style={{
        objectPosition: position || 'center',
      }}
      placeholder="blur"
      blurDataURL={blurDataURL || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg=='}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    />
    <Caption caption={caption} />
  </div>
)

const GenericMedia: FC<GenericProps> = ({ children, caption }) => (
  <div>
    {children}
    <Caption caption={caption} />
  </div>
)

export const Media: FC<MediaProps> = (mp) =>
  mp.kind === 'generic' ? (
    <GenericMedia {...mp} />
  ) : mp.kind === 'image' ? (
    <ImageLocal {...mp} />
  ) : mp.kind === 'video' ? (
    <Video {...mp} />
  ) : null

export type MediaSources = Array<{
  type: string
  src: string
}>
export const Audio: FC<{
  id: string
  sources: MediaSources
  className?: string
  small?: boolean
}> = ({ id, sources, small, className }) => {
  const handlePlay = () => pausePlayingAudio(id)
  return (
    <audio
      controls
      playsInline
      className={'h-8 outline-none ' + className}
      style={small ? { maxWidth: '50%' } : {}}
      onPlay={handlePlay}
      id={id}
    >
      {sources.map((s) => (
        <source type={s.type} key={s.src} src={s.src} />
      ))}
    </audio>
  )
}

export const MediaGroup: FC<MediaGroupProps> = ({ media }) => {
  const onChangeIndex = () => {
    pausePlayingVideo('wtf')
    pausePlayingAudio('wtf')
  }
  // const show = media.length > 1
  return (
    <Swiper
      onChange={onChangeIndex}
      media={media}
    />
  )
}

export const MediaGrid: FC<MediaGridProps> = ({ media, aspect = 1 }) => (
  <div className="min-w-1/2 mx-auto flex-1">
    <div className="grid grid-flow-row gap-2 md:gap4 lg:gap-8 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3">
      {media.map((m, idx) => (
        <div key={idx} className="relative" style={{ aspectRatio: aspect }}>
          <Media {...m} />
        </div>
      ))}
    </div>
  </div>
)
