import React, { FC } from 'react'
import { MediaGrid } from '../media'
// import Link from 'next/link'

import { ShortProps, ProjectRowShort } from './project'
import { Project } from './types'

const ProjectRowPage: FC<ShortProps> = ({
  project
}) => {
  return (
    <div className="bg-slate-300 text-slate-900  dark:bg-slate-800 dark:text-slate-300 p-4">
      <div className="p-2 lg:p-8 xl:p-8 flex-1 ">
        <div className="space-y-4"><project.info.page /></div>
      </div>
      <div className="p-2 lg:pl-8 lg:pb-8">
        <MediaGrid media={project.media} aspect={project.aspect} />
      </div>
    </div>
  )
}

const ProjectRow: FC<{ project: Project }> = ({
  project
}) => {
  return (
    <div id={project.key}>
      <ProjectRowShort project={project} onClick={() => null} open={true} />
      <ProjectRowPage project={project} open={false} />
    </div>
  )
}

export default ProjectRow
