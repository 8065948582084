import React, { useState, useRef, useEffect, FC } from 'react'
import { MediaGroup } from '../media'
import Link from '../link'
import { Project } from './types'

export type ShortProps = {
  project: Project
  open: boolean
  onClick?: (e: React.MouseEvent) => void
}

type LongProps = ShortProps // & {open: boolean}

const ProjectRowLong: FC<LongProps> = ({
  project,
  open
}) => {
  const [h, setH] = useState(0)
  const [o, setO] = useState(open)
  const innerRef = useRef(null)
  useEffect(() => {
    if (open) {
      setO(true)
      setTimeout(() => {
        const h = innerRef.current?.scrollHeight
        setH(h)
      }, 5)
    } else {
      setH(0)
      setTimeout(() => setO(false), 500)
    }
  }, [open])

  useEffect(() => {
    const resize = () => {
      if (o) {
        const h = innerRef.current.scrollHeight
        setTimeout(() => setH(h), 50)
      }
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [o])

  return o
    ? (
      <div
        className="overflow-hidden bg-slate-300 text-slate-900  dark:bg-slate-800 dark:text-slate-300  "
        style={{
          height: h + 'px',
          transition: 'height 0.5s ease'
        }}
      >
        <div ref={innerRef} className="flex flex-col sm:flex-row ">
          <div className="flex flex-col w-full sm:w-1/2 flex-none">
            <div className="w-full flex-none" style={{ aspectRatio: project.aspect || 16 / 9 }}>
              <MediaGroup media={project.media} />
            </div>
            <div className="flex-grow" />
          </div>
          <div className="p-2 lg:p-8 xl:p-8 max-w-4xl flex-1">
            <div className="space-y-4">
              <project.info.long>
                <div className='pt-4 pb-4'><Link className='p-2 rounded' href={'/' + project.key}>more</Link></div>
              </project.info.long>
            </div>
          </div>
        </div>
      </div>
    )
    : null
}

export const ProjectRowShort: FC<ShortProps> = ({
  open,
  project,
  onClick
}) => (
  <div
    className={"flex cursor-pointer w-full transition-colors ease-out duration-500 " + (
      open
        ? 'opacity-100 bg-red-400 text-slate-900 dark:bg-red-800 dark:text-red-200'
        : 'opacity-90 bg-slate-300 text-slate-900 hover:bg-red-200 hover:opacity-95 dark:hover:bg-red-900 dark:hover:text-red-300 dark:bg-slate-800 dark:text-slate-300 '
    )}
    onClick={onClick}
  >
    <div className="w-32 hidden lg:flex xl:flex align-middle p-4 whitespace-no-wrap uppercase select-none">{project.date}</div>
    <div className="w-64 whitespace-no-wrap align-middle p-4 tracking-wider uppercase select-none">{project.title}</div>
    <div className="hidden lg:flex xl:flex align-middle p-4 uppercase select-none"><project.info.short /></div>
  </div>
)

const ProjectRow: FC<ShortProps & { pid: string }> = ({
  project,
  pid
}) => {
  const [open, setOpen] = useState<boolean>(pid === project.key)
  const handleClick = () => setOpen(!open)
  return (
    <div id={project.key}>
      <ProjectRowShort key={'short' + project.key} project={project} onClick={handleClick} open={open} />
      <ProjectRowLong project={project} open={open} />
    </div>
  )
}

export default ProjectRow
