import React, { FC, MouseEventHandler, useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { Media, MediaProps } from './media'


const ArrowL = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
  </svg>
)

const ArrowR = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
)
type SwiperProps = {
  media: MediaProps[]
  onChange: () => void
}
export const Swiper: FC<SwiperProps> = ({
  media,
  onChange
}) => {
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    initial: 0,
    created: () => setLoaded(true),
    slideChanged: onChange
  })
  const rightClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    instanceRef.current?.next()
  }
  const leftClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    instanceRef.current?.prev()
  }


  return (
    <div ref={sliderRef} className="keen-slider h-full relative">
      {
        media.map((m, idx) => (
          <div key={idx} className="keen-slider__slide " >
            <Media {...m} />
          </div>
        ))
      }
      {loaded && instanceRef.current && (
        <>
          <div className="absolute left-0 h-full flex items-center pointer-events-none">
            <button className="bg-gray-900 bg-opacity-15 p-2 text-gray-100 w-12 h-12 pointer-events-auto" onClick={leftClick}>
              <ArrowL />
            </button>
          </div>
          <div className="absolute right-0 h-full flex items-center pointer-events-none">
            <button className="bg-gray-900 bg-opacity-15 p-2 text-gray-100 w-12 h-12 pointer-events-auto" onClick={rightClick}>
              <ArrowR />
            </button>
          </div>
        </>
      )}
    </div>
  )
}
