import React, { FC } from 'react'
import Link from 'next/link'

export type LinkProps = {
  href: string
  external?: true
  className?: string
  children: React.ReactNode
}

export const LinkPlain: FC<LinkProps> = ({
  href,
  external,
  className,
  children,
}) =>
  external ? (
    <a target="_blank" className={className} href={href} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link href={href} className={className}>{children}</Link>
  )

const MainLink: FC<LinkProps> = ({ href, external, className, children }) => (
  <LinkPlain
    external={external}
    className={'bg-orange-100 text-slate-950 dark:bg-slate-950 dark:text-slate-300 pl-1 pr-1  rounded ' + className}
    href={href}
  >
    {children}
  </LinkPlain>
)

export default MainLink
